exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jinesh-jsx": () => import("./../../../src/pages/aboutJinesh.jsx" /* webpackChunkName: "component---src-pages-about-jinesh-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-academy-[id]-jsx": () => import("./../../../src/pages/academy/[id].jsx" /* webpackChunkName: "component---src-pages-academy-[id]-jsx" */),
  "component---src-pages-academy-advisor-jsx": () => import("./../../../src/pages/academy/Advisor.jsx" /* webpackChunkName: "component---src-pages-academy-advisor-jsx" */),
  "component---src-pages-academy-affordability-jsx": () => import("./../../../src/pages/academy/Affordability.jsx" /* webpackChunkName: "component---src-pages-academy-affordability-jsx" */),
  "component---src-pages-academy-amortisation-jsx": () => import("./../../../src/pages/academy/Amortisation.jsx" /* webpackChunkName: "component---src-pages-academy-amortisation-jsx" */),
  "component---src-pages-academy-benefits-of-overpayments-jsx": () => import("./../../../src/pages/academy/BenefitsOfOverpayments.jsx" /* webpackChunkName: "component---src-pages-academy-benefits-of-overpayments-jsx" */),
  "component---src-pages-academy-faq-advisor-faq-jsx": () => import("./../../../src/pages/academy/faq/AdvisorFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-advisor-faq-jsx" */),
  "component---src-pages-academy-faq-affordability-faq-jsx": () => import("./../../../src/pages/academy/faq/AffordabilityFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-affordability-faq-jsx" */),
  "component---src-pages-academy-faq-amortisation-faq-jsx": () => import("./../../../src/pages/academy/faq/AmortisationFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-amortisation-faq-jsx" */),
  "component---src-pages-academy-faq-benefits-of-overpayments-faq-jsx": () => import("./../../../src/pages/academy/faq/BenefitsOfOverpaymentsFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-benefits-of-overpayments-faq-jsx" */),
  "component---src-pages-academy-faq-fixed-variable-faq-jsx": () => import("./../../../src/pages/academy/faq/FixedVariableFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-fixed-variable-faq-jsx" */),
  "component---src-pages-academy-faq-green-mortgages-faq-jsx": () => import("./../../../src/pages/academy/faq/GreenMortgagesFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-green-mortgages-faq-jsx" */),
  "component---src-pages-academy-faq-impact-the-cost-faq-jsx": () => import("./../../../src/pages/academy/faq/ImpactTheCostFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-impact-the-cost-faq-jsx" */),
  "component---src-pages-academy-faq-increase-chances-faq-jsx": () => import("./../../../src/pages/academy/faq/IncreaseChancesFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-increase-chances-faq-jsx" */),
  "component---src-pages-academy-faq-key-remortgage-faq-jsx": () => import("./../../../src/pages/academy/faq/KeyRemortgageFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-key-remortgage-faq-jsx" */),
  "component---src-pages-academy-faq-lock-a-deal-faq-jsx": () => import("./../../../src/pages/academy/faq/LockADealFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-lock-a-deal-faq-jsx" */),
  "component---src-pages-academy-faq-ltvfaq-jsx": () => import("./../../../src/pages/academy/faq/LTVFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-ltvfaq-jsx" */),
  "component---src-pages-academy-faq-making-overpayments-faq-jsx": () => import("./../../../src/pages/academy/faq/MakingOverpaymentsFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-making-overpayments-faq-jsx" */),
  "component---src-pages-academy-faq-mortagage-consider-faq-jsx": () => import("./../../../src/pages/academy/faq/MortagageConsiderFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-mortagage-consider-faq-jsx" */),
  "component---src-pages-academy-faq-mortgage-early-faq-jsx": () => import("./../../../src/pages/academy/faq/MortgageEarlyFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-mortgage-early-faq-jsx" */),
  "component---src-pages-academy-faq-overpayments-faq-jsx": () => import("./../../../src/pages/academy/faq/OverpaymentsFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-overpayments-faq-jsx" */),
  "component---src-pages-academy-faq-overpayments-preferences-faq-jsx": () => import("./../../../src/pages/academy/faq/OverpaymentsPreferencesFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-overpayments-preferences-faq-jsx" */),
  "component---src-pages-academy-faq-remortgage-faq-jsx": () => import("./../../../src/pages/academy/faq/RemortgageFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-remortgage-faq-jsx" */),
  "component---src-pages-academy-faq-repayment-charge-faq-jsx": () => import("./../../../src/pages/academy/faq/RepaymentChargeFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-repayment-charge-faq-jsx" */),
  "component---src-pages-academy-faq-repayment-mortgages-faq-jsx": () => import("./../../../src/pages/academy/faq/RepaymentMortgagesFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-repayment-mortgages-faq-jsx" */),
  "component---src-pages-academy-faq-same-lender-faq-jsx": () => import("./../../../src/pages/academy/faq/SameLenderFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-same-lender-faq-jsx" */),
  "component---src-pages-academy-faq-self-employed-faq-jsx": () => import("./../../../src/pages/academy/faq/SelfEmployedFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-self-employed-faq-jsx" */),
  "component---src-pages-academy-faq-six-stapes-faq-jsx": () => import("./../../../src/pages/academy/faq/SixStapesFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-six-stapes-faq-jsx" */),
  "component---src-pages-academy-faq-time-to-switch-faq-jsx": () => import("./../../../src/pages/academy/faq/TimeToSwitchFAQ.jsx" /* webpackChunkName: "component---src-pages-academy-faq-time-to-switch-faq-jsx" */),
  "component---src-pages-academy-fixed-variable-jsx": () => import("./../../../src/pages/academy/FixedVariable.jsx" /* webpackChunkName: "component---src-pages-academy-fixed-variable-jsx" */),
  "component---src-pages-academy-green-mortgages-jsx": () => import("./../../../src/pages/academy/GreenMortgages.jsx" /* webpackChunkName: "component---src-pages-academy-green-mortgages-jsx" */),
  "component---src-pages-academy-impact-the-cost-jsx": () => import("./../../../src/pages/academy/ImpactTheCost.jsx" /* webpackChunkName: "component---src-pages-academy-impact-the-cost-jsx" */),
  "component---src-pages-academy-increase-chances-jsx": () => import("./../../../src/pages/academy/IncreaseChances.jsx" /* webpackChunkName: "component---src-pages-academy-increase-chances-jsx" */),
  "component---src-pages-academy-jsx": () => import("./../../../src/pages/academy.jsx" /* webpackChunkName: "component---src-pages-academy-jsx" */),
  "component---src-pages-academy-key-remortgage-jsx": () => import("./../../../src/pages/academy/KeyRemortgage.jsx" /* webpackChunkName: "component---src-pages-academy-key-remortgage-jsx" */),
  "component---src-pages-academy-lock-a-deal-jsx": () => import("./../../../src/pages/academy/LockADeal.jsx" /* webpackChunkName: "component---src-pages-academy-lock-a-deal-jsx" */),
  "component---src-pages-academy-ltv-jsx": () => import("./../../../src/pages/academy/LTV.jsx" /* webpackChunkName: "component---src-pages-academy-ltv-jsx" */),
  "component---src-pages-academy-making-overpayments-jsx": () => import("./../../../src/pages/academy/MakingOverpayments.jsx" /* webpackChunkName: "component---src-pages-academy-making-overpayments-jsx" */),
  "component---src-pages-academy-mortgage-consider-jsx": () => import("./../../../src/pages/academy/MortgageConsider.jsx" /* webpackChunkName: "component---src-pages-academy-mortgage-consider-jsx" */),
  "component---src-pages-academy-mortgage-early-jsx": () => import("./../../../src/pages/academy/MortgageEarly.jsx" /* webpackChunkName: "component---src-pages-academy-mortgage-early-jsx" */),
  "component---src-pages-academy-overpayments-jsx": () => import("./../../../src/pages/academy/Overpayments.jsx" /* webpackChunkName: "component---src-pages-academy-overpayments-jsx" */),
  "component---src-pages-academy-overpayments-preferences-jsx": () => import("./../../../src/pages/academy/OverpaymentsPreferences.jsx" /* webpackChunkName: "component---src-pages-academy-overpayments-preferences-jsx" */),
  "component---src-pages-academy-remortgage-jsx": () => import("./../../../src/pages/academy/Remortgage.jsx" /* webpackChunkName: "component---src-pages-academy-remortgage-jsx" */),
  "component---src-pages-academy-repayment-charge-jsx": () => import("./../../../src/pages/academy/RepaymentCharge.jsx" /* webpackChunkName: "component---src-pages-academy-repayment-charge-jsx" */),
  "component---src-pages-academy-repayment-mortgages-jsx": () => import("./../../../src/pages/academy/RepaymentMortgages.jsx" /* webpackChunkName: "component---src-pages-academy-repayment-mortgages-jsx" */),
  "component---src-pages-academy-same-lender-jsx": () => import("./../../../src/pages/academy/SameLender.jsx" /* webpackChunkName: "component---src-pages-academy-same-lender-jsx" */),
  "component---src-pages-academy-self-employed-jsx": () => import("./../../../src/pages/academy/SelfEmployed.jsx" /* webpackChunkName: "component---src-pages-academy-self-employed-jsx" */),
  "component---src-pages-academy-six-stapes-jsx": () => import("./../../../src/pages/academy/SixStapes.jsx" /* webpackChunkName: "component---src-pages-academy-six-stapes-jsx" */),
  "component---src-pages-academy-time-to-switch-jsx": () => import("./../../../src/pages/academy/TimeToSwitch.jsx" /* webpackChunkName: "component---src-pages-academy-time-to-switch-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-christmas-terms-jsx": () => import("./../../../src/pages/christmas-terms.jsx" /* webpackChunkName: "component---src-pages-christmas-terms-jsx" */),
  "component---src-pages-complaints-jsx": () => import("./../../../src/pages/complaints.jsx" /* webpackChunkName: "component---src-pages-complaints-jsx" */),
  "component---src-pages-deal-highlights-jsx": () => import("./../../../src/pages/deal-highlights.jsx" /* webpackChunkName: "component---src-pages-deal-highlights-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-get-in-touch-jsx": () => import("./../../../src/pages/get-in-touch.jsx" /* webpackChunkName: "component---src-pages-get-in-touch-jsx" */),
  "component---src-pages-ikea-terms-jsx": () => import("./../../../src/pages/ikea-terms.jsx" /* webpackChunkName: "component---src-pages-ikea-terms-jsx" */),
  "component---src-pages-intermediary-terms-jsx": () => import("./../../../src/pages/intermediary-terms.jsx" /* webpackChunkName: "component---src-pages-intermediary-terms-jsx" */),
  "component---src-pages-main-subdomain-index-jsx": () => import("./../../../src/pages/main-subdomain/index.jsx" /* webpackChunkName: "component---src-pages-main-subdomain-index-jsx" */),
  "component---src-pages-mortgage-prizedraw-terms-jsx": () => import("./../../../src/pages/mortgage-prizedraw-terms.jsx" /* webpackChunkName: "component---src-pages-mortgage-prizedraw-terms-jsx" */),
  "component---src-pages-mortgagehelp-terms-jsx": () => import("./../../../src/pages/mortgagehelp-terms.jsx" /* webpackChunkName: "component---src-pages-mortgagehelp-terms-jsx" */),
  "component---src-pages-mortgageleads-terms-jsx": () => import("./../../../src/pages/mortgageleads-terms.jsx" /* webpackChunkName: "component---src-pages-mortgageleads-terms-jsx" */),
  "component---src-pages-mortgages-cashback-calculator-jsx": () => import("./../../../src/pages/mortgages/cashback-calculator.jsx" /* webpackChunkName: "component---src-pages-mortgages-cashback-calculator-jsx" */),
  "component---src-pages-mortgages-digit-jsx": () => import("./../../../src/pages/mortgages/Digit.jsx" /* webpackChunkName: "component---src-pages-mortgages-digit-jsx" */),
  "component---src-pages-mortgages-discount-jsx": () => import("./../../../src/pages/mortgages/discount.jsx" /* webpackChunkName: "component---src-pages-mortgages-discount-jsx" */),
  "component---src-pages-mortgages-fixed-rate-jsx": () => import("./../../../src/pages/mortgages/fixed-rate.jsx" /* webpackChunkName: "component---src-pages-mortgages-fixed-rate-jsx" */),
  "component---src-pages-mortgages-flexible-mortgage-jsx": () => import("./../../../src/pages/mortgages/flexible-mortgage.jsx" /* webpackChunkName: "component---src-pages-mortgages-flexible-mortgage-jsx" */),
  "component---src-pages-mortgages-get-free-quote-jsx": () => import("./../../../src/pages/mortgages/get-free-quote.jsx" /* webpackChunkName: "component---src-pages-mortgages-get-free-quote-jsx" */),
  "component---src-pages-mortgages-get-quote-jsx": () => import("./../../../src/pages/mortgages/get-quote.jsx" /* webpackChunkName: "component---src-pages-mortgages-get-quote-jsx" */),
  "component---src-pages-mortgages-overpayment-calculator-jsx": () => import("./../../../src/pages/mortgages/overpayment-calculator.jsx" /* webpackChunkName: "component---src-pages-mortgages-overpayment-calculator-jsx" */),
  "component---src-pages-mortgages-remortgage-jsx": () => import("./../../../src/pages/mortgages/remortgage.jsx" /* webpackChunkName: "component---src-pages-mortgages-remortgage-jsx" */),
  "component---src-pages-mortgages-repayment-mortgages-jsx": () => import("./../../../src/pages/mortgages/repayment-mortgages.jsx" /* webpackChunkName: "component---src-pages-mortgages-repayment-mortgages-jsx" */),
  "component---src-pages-mortgages-tracker-jsx": () => import("./../../../src/pages/mortgages/tracker.jsx" /* webpackChunkName: "component---src-pages-mortgages-tracker-jsx" */),
  "component---src-pages-mortgages-variable-rate-jsx": () => import("./../../../src/pages/mortgages/variable-rate.jsx" /* webpackChunkName: "component---src-pages-mortgages-variable-rate-jsx" */),
  "component---src-pages-pps-terms-jsx": () => import("./../../../src/pages/pps-terms.jsx" /* webpackChunkName: "component---src-pages-pps-terms-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-promo-terms-jsx": () => import("./../../../src/pages/promo-terms.jsx" /* webpackChunkName: "component---src-pages-promo-terms-jsx" */),
  "component---src-pages-raffle-terms-jsx": () => import("./../../../src/pages/raffle-terms.jsx" /* webpackChunkName: "component---src-pages-raffle-terms-jsx" */),
  "component---src-pages-referral-policy-jsx": () => import("./../../../src/pages/referral-policy.jsx" /* webpackChunkName: "component---src-pages-referral-policy-jsx" */),
  "component---src-pages-remortgage-terms-jsx": () => import("./../../../src/pages/remortgage-terms.jsx" /* webpackChunkName: "component---src-pages-remortgage-terms-jsx" */),
  "component---src-pages-shop-with-sprive-terms-jsx": () => import("./../../../src/pages/shop-with-sprive-terms.jsx" /* webpackChunkName: "component---src-pages-shop-with-sprive-terms-jsx" */),
  "component---src-pages-survey-faq-jsx": () => import("./../../../src/pages/survey-faq.jsx" /* webpackChunkName: "component---src-pages-survey-faq-jsx" */),
  "component---src-pages-survey-privacy-jsx": () => import("./../../../src/pages/survey-privacy.jsx" /* webpackChunkName: "component---src-pages-survey-privacy-jsx" */),
  "component---src-pages-survey-terms-jsx": () => import("./../../../src/pages/survey-terms.jsx" /* webpackChunkName: "component---src-pages-survey-terms-jsx" */),
  "component---src-pages-switch-mortgage-jsx": () => import("./../../../src/pages/switch-mortgage.jsx" /* webpackChunkName: "component---src-pages-switch-mortgage-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-verify-user-jsx": () => import("./../../../src/pages/verify-user.jsx" /* webpackChunkName: "component---src-pages-verify-user-jsx" */),
  "component---src-pages-view-deals-jsx": () => import("./../../../src/pages/view-deals.jsx" /* webpackChunkName: "component---src-pages-view-deals-jsx" */),
  "component---src-pages-xmas-giveaway-2023-terms-jsx": () => import("./../../../src/pages/xmas-giveaway-2023-terms.jsx" /* webpackChunkName: "component---src-pages-xmas-giveaway-2023-terms-jsx" */),
  "component---src-templates-blog-detail-jsx": () => import("./../../../src/templates/blog-detail.jsx" /* webpackChunkName: "component---src-templates-blog-detail-jsx" */),
  "component---src-templates-non-lender-template-page-jsx": () => import("./../../../src/templates/non-lender-template-page.jsx" /* webpackChunkName: "component---src-templates-non-lender-template-page-jsx" */),
  "component---src-templates-template-page-jsx": () => import("./../../../src/templates/template-page.jsx" /* webpackChunkName: "component---src-templates-template-page-jsx" */)
}


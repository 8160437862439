import React from 'react';
import SEO from '../components/SEO';

import TermsSection from '../components/CommonSections/TermsSection';

const ShopWithSpriveTermsPage = () => (
  <>
    <SEO title="Shop with Sprive Terms and Conditions" />
    <TermsSection
      title="Shop with Sprive Terms and Conditions"
      content={(<SecondSection />)}
    />
  </>
);

const SecondSection = () => (
    <>
      <p>
        <ul>
          <li>
            You must purchase a Shopping Card for any participating supermarket brand before 23:59 on 31/01/25.
          </li>
          <br/>
          <li>
            The Shopping Card purchased must be a value of at least £50.
          </li>
          <br/>
          <li>
            You must have not used the Shop with Sprive feature for at least three months or at all.
          </li>
          <br/>
          <li>
            Your £20 will be credited to your Sprive account by 1st February 2025.
          </li>
          <br/>
          <li>
            The £20 reward cannot be withdrawn and be paid to your mortgage lender only.
          </li>
          <br/>
          <li>
            This offer is eligible for any of the available supermarkets which include ASDA, Tesco, Sainsbury’s, M&S, Waitrose, Morrisons and Iceland.
          </li>
          <br/>
        </ul>
      </p>
    </>
);

export default ShopWithSpriveTermsPage;
